.formLayout-body {
  display: grid;
  grid-template-columns: minmax(auto, 300px);
  justify-content: center;
  justify-items: center;
}

.formLayout-body form {
  background-color: #fff;
  padding: 20px ;
  border-radius: .25rem;
  border: 1px solid rgba(0,0,0,.125);
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.2);
}

.formLayout-body select, .formLayout-body input {
  padding: .375rem .75rem;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  margin: 6px 0;
  line-height: 1.5;
  background-clip: padding-box;
}

.formLayout-body select {
  width: 100%;
}

.formLayout-body button {
  border: 1px solid transparent;
  padding: .375rem .75rem;
  border-radius: .25rem;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
}