nav {
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.03);
  background-color: #fff;
}

nav section {
  justify-content: space-between;
  align-items: center;
  max-width: 1324px;
  margin: 0px auto 30px auto;
  display: flex;
  width: auto;
}

nav section div:first-of-type {
  display: flex;
  align-items: center;
}

nav section div:first-of-type img {
  height: 70px;
}

nav section div:first-of-type span {
  display: inline-block;
  margin-right: 10px;
  font-size: 18px;
}

nav section div:first-of-type span:last-of-type {
  background-color: #ff5050;
  border-radius: 3px;
  padding: 0px 6px;
  color: #fff;
}

nav section div:last-of-type a {
  text-decoration: none;
}

nav section div:last-of-type i {
  transition: all 0.3s ease 0s;
  color: #ff5050;
  cursor: pointer;
  font-size: 20px;
  margin: 0 2px;
}

nav section div:last-of-type i:hover {
  color: #ff9a9a;
}
