.task-container {
  display: grid;
  grid-template-columns: minmax(auto, 1200px);
  justify-content: center;
}

.task {
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  display: flex;
}

.task-card {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #fff;
  border-radius: 0.25rem;
  text-align: center;
  min-width: 200px;
  max-width: 220px;
  margin: 20px;
}

.task-card:first-of-type {
  margin-left: 0;
}

.task-card:last-of-type {
  margin-left: 0;
}

.task-card-header {
  background-color: #f8f9fa;
  padding: 10px 0;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.task-card-header h4 {
  margin: 10px 0;
  font-size: 20px;
}

.task-card-body {
  margin: 0 10px;
}

.task-card-body p:first-of-type {
  font-size: 20px;
}

.task-card-footer {
  background-color: #f8f9fa;
  padding: 10px 0;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.task-card-footer button {
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  cursor: pointer;
  background-color: #ff5050;
  color: #fff;
}
